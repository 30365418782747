<template>

  <div v-if="reservationsData">

    <vs-table :data="reservationsData"
              :pagination="reservationsData.length > maxItems ? true : false" :max-items="maxItems">
      <template slot="header">

        <div class="d-flex mi-align-horizontal-left">
          <div class="d-inline-block float-left">
            <h2 v-if="type === 'CANCELED'">Odpovedni prevozi</h2>
            <h2 v-if="type === 'DECLINED'">Zavrnjeni prevozi</h2>
          </div>
          <vs-chip class="mt-1 ml-3">{{ reservationsData.length }}</vs-chip>
        </div>

      </template>
      <template slot="thead">
        <vs-th sort-key="id">
          <!-- empty -->
        </vs-th>
        <vs-th sort-key="date" style="width: 130px;">
          Datum
        </vs-th>
        <vs-th sort-key="unit_id">
          Enota
        </vs-th>
        <vs-th sort-key="customer_name" style="width: 150px;">
          Potnik
        </vs-th>
        <vs-th sort-key="reason">
          Razlog
        </vs-th>
        <vs-th sory-key="id" style="width: 200px;">
          Akcije
        </vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="data[index].id" v-for="(tr, index) in data" :data="tr">
          <vs-td :data="index">{{ index + 1 }}</vs-td>
          <vs-td :data="moment(data[index].date)">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              {{ moment(data[index].date).format('DD. MM. YYYY') }}
            </vs-col>
          </vs-td>

          <vs-td :data="data[index].unit_id">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              {{ data[index].unit.name }}
            </vs-col>
          </vs-td>

          <vs-td :data="data[index].customer_name">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              <div v-if="data[index].customer === null">{{ data[index].customer_name }}</div>
              <div v-if="data[index].customer && data[index].customer.id" @click="() => { customerId = data[index].customer.id; sidebarActive = true; }" class="text-primary">{{ data[index].customer.firstName + ' ' + data[index].customer.lastName }}</div>
            </vs-col>
          </vs-td>

          <vs-td :data="data[index].reason">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              {{ data[index].reason }}
            </vs-col>
          </vs-td>

          <vs-td :data="data[index].id">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">

              <vs-button type="filled" color="primary" icon="edit" size="small"
                         class="vs-lg-12 mt-2" @click.stop="editCanceledOrDeclinedReservation(data[index].id)">Uredi zapis
              </vs-button>

            </vs-col>
          </vs-td>

        </vs-tr>
      </template>
    </vs-table>

    <div id="parentx">
      <vs-sidebar position-right parent="body" default-index="1" color="primary" class="customerSidebar" spacer
                  v-model="sidebarActive">

        <Customer v-if="customerId && sidebarActive" :customerId="customerId" />
      </vs-sidebar>
    </div>


  </div>
</template>

<script>
import Vue from 'vue';
import Customer from '@/components/Sopotniki/customers/Customer'


export default {
  name: 'CanceledOrDeclinedReservations',
  props: {
    reservationsData: Array,
    type: String
  },
  components: {
    Customer
  },

  data() {
    return {
      maxItems: 500,
      customerId: null,
      sidebarActive: false
    }
  },

  computed: {},

  mounted() {
    const _this = this;

  },

  destroyed() {
    console.log("destroyed");
    const _this = this;

  },

  beforeDestroy() {
    console.log("beforeDestroy");
    const _this = this;
  },

  methods: {
    editCanceledOrDeclinedReservation(reservationId) {
      const _this = this;

      if(_this.type === 'CANCELED') {
        _this.$router.push({name: 'CanceledReservationEdit', params: {reservationId: reservationId}});
      } else if(_this.type === 'DECLINED') {
        _this.$router.push({name: 'DeclinedReservationEdit', params: {reservationId: reservationId}});
      }
    },

    getFiltered(e) {
      console.log(e) //output the filtered items
    },

    stopTheEvent: (event) => {
      event.stopPropagation();
      event.preventDefault();
    },

  }
}
</script>

