<template>
  <div id="customerContainer" class="relative">

    <div class="loadingContainer">
      <div v-if="customerData">


        <vs-list>
          <vs-list-item class="customer"
                        :title="customerData.first_name + ' ' + customerData.last_name"
                        :subtitle="customerData.notes">

                        <template slot="avatar">
                          <div class="avatarWithStatus">
                            <vs-avatar size="60px" :src="customerData.gender === 'male' ? sir : madam" :color="customerData.gender === 'male' ? '#dff8ff' : '#ffffdf'"  />
                            <vs-avatar v-if="customerData.active === 1" size="small" class="status" color="success" icon="check_circle"/>
                            <vs-avatar v-if="customerData.active === 0" size="small" class="status" color="danger" icon="radio_button_unchecked"/>
                          </div>
                        </template>

          </vs-list-item>

          <vs-list-header icon="home" :title="customerData.gender === 'male' ? 'Kontaktni podatki uporabnika' : 'Kontaktni podatki uporabnice'" color="info"></vs-list-header>
          <vs-list-item :title="customerData.addresses.quick_search" icon="location_on"></vs-list-item>

          <template>
            <div>
              <gmap-map ref="map" :center="center" :zoom="15" style="width: 100%; height: 500px">

                <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false"/>
                <GmapMarker
                  :key="index"
                  v-for="(item, index) in markers"
                  :position="item.position"
                  :clickable="true"
                  :draggable="false"
                  @click="toggleInfoWindow(item, index)"
                />
              </gmap-map>
            </div>
          </template>

          <vs-list-item :title="customerData.phone" :subtitle="'Telefonska številka'"
                        icon="call"></vs-list-item>
          <vs-list-item :title="customerData.email ? customerData.email : 'ne uporablja emaila'"
                        :subtitle="'E-naslov'" icon="email"></vs-list-item>

        </vs-list>

<!--        <div class="flex mt-5">-->
<!--          <div class="w-full">-->
<!--            <ConsentsWidget :user-id="customerData.id" :consent-giver="'CUSTOMER'" :mode="'view'"></ConsentsWidget>-->
<!--          </div>-->
<!--        </div>-->


        <vs-row
          vs-align="center"
          vs-type="flex" vs-justify="space-around" vs-w="12" class="mt-5">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="8" class="mt-1">
            <vs-button
              style="width: 100%"
              block
              @click="printCard(customerData)">
              Natisni izkaznico
            </vs-button>
          </vs-col>
        </vs-row>

        <vs-row
          vs-align="center"
          vs-type="flex" vs-justify="space-around" vs-w="12" class="mt-5">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
            <vs-button color="primary" icon="edit" @click="editCustomer()">Uredi</vs-button>
          </vs-col>
        </vs-row>


      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import DirectionsRenderer from "../../../helpers/DirectionsRenderer";
  import ConsentsWidget from "../consentManagement/ConsentWidget";
  import sir from '../../../assets/images/portrait/sir.svg';
  import madam from '../../../assets/images/portrait/madam.svg';

  let directionsService = null;
  let directionsDisplay = null;
  let geoCoder = null;

  export default {
    name: 'reservations-calendar',

    components: {
      DirectionsRenderer,
      ConsentsWidget
    },
    props: {
      customerId: Number
    },
    data() {
      return {
        sir: sir,
        madam: madam,
        customerData: null,

        center: {lat: 46.0569, lng: 14.5058},
        markers: [],

        infoWindowPos: null,
        infoWinOpen: false,
        currentMidx: null,

        infoOptions: {
          content: '',
          pixelOffset: {
            width: 0,
            height: -35
          }
        },
      }
    },

    computed: {},

    mounted() {
      const _this = this;


      _this.getCustomer();

    },

    watch: {
      customerId: {
        handler(val, oldVal) {
          console.log("CHANGE!!!!");
          this.customerData = null;


          if (val) {
            this.getCustomer();
          }
        }
      }
    },

    methods: {
      getCustomer() {
        const _this = this;


        this.$vs.loading({
          container: '#customerContainer .loadingContainer',
        })


        Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'customers/' + _this.customerId)
          .then((res) => {

            _this.customerData = res.data.data;
            _this.showCustomerMarker();
            _this.$vs.loading.close('#customerContainer .loadingContainer > .con-vs-loading');

          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close('#customerContainer .loadingContainer > .con-vs-loading');
            return false
          });
      },

      editCustomer() {
        const _this = this;

        _this.$router.push({name: 'Uporabnik', params: { customerId: _this.customerData.id}})
      },

      toggleInfoWindow: function(marker, idx) {
        this.infoWindowPos = marker.position;
        this.infoOptions.content = marker.infoText;

        if (this.currentMidx == idx) {
          this.infoWinOpen = !this.infoWinOpen;
        }
        else {
          this.infoWinOpen = true;
          this.currentMidx = idx;

        }
      },

      showCustomerMarker() {
        const _this = this;

        geoCoder = new google.maps.Geocoder();
        geoCoder.geocode({'address': _this.customerData.addresses.quick_search}, function (results, status) {
          if (status === 'OK') {
            _this.center = results[0].geometry.location;

            console.log("_this.center", results[0].geometry.location);

            let position = {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            }

            _this.markers.push({
              infoText: _this.customerData.first_name + ' ' + _this.customerData.last_name,
              position: position
            });

          } else {
            console.error('Geocode was not successful for the following reason: ' + status);
          }
        });

      },

      printCard(customer) {
        const _this = this;

        _this.$vs.loading();

        Vue.prototype.$http.get('https://pdf.sopotniki.org/html-to-pdf/' + encodeURIComponent('https://admin-2-0.sopotniki.org/customer/id-card/' + customer.id + '?jwt=' + JSON.parse(localStorage.getItem("userInfo")).token),
          {
            headers: {'secret': 'sopotniki-it'}, responseType: 'blob'
          }
        )
          .then((res) => {
            const file = new Blob(
              [res.data],
              {type: 'application/pdf'});

            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(file)
            link.download = `izkaznica_${customer.first_name}_${customer.last_name}.pdf`
            link.click();
            _this.$vs.loading.close();
          });
      },

    }
  }
</script>

